<template>
  <div class="calendar-subview">
    <teleport to="#header-action">
      <Btn v-if="helperService.userHasPermission('essaievenement_oadd')" class="action-btn" icon="plus" color="primary"
        text="Ajouter une tâche expérimentale" @click="openModal('addExperimentalTask')" />
    </teleport>

    <List :key="componentKey" :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key"
      :target="list.target" disableCheckbox>
      <template v-slot:statut="{ item }">
        <div class="essai-check">
          <template v-if="item?.type?.uid === 'NOTATION' && item?.date_realisation && states[item.id] === 'REALISE' ">
            <SvgIcon name="check" />
          </template>
          <template v-if="item?.type?.uid !== 'NOTATION' && item?.date_realisation ">
            <SvgIcon name="check" />
          </template>
        </div>
      </template>

      <template v-slot:designation="{ item }">
        <!-- TODO: JL -->
        <!-- Permet de checker si la notation est issue d'un protocole -->
        <!-- {{ notationsWithProtocolParent.includes(item.id) }} -->
        {{ item.designation ? `${item.designation} (${item.type.designation})` : item.type.designation }}
        <template v-if="notationsObj[item.id]?.est_herite">
          (héritée)
        </template>

      </template>

      <template v-slot:previsionnelle="{ item }">
        <template v-if="item.date_previsionnelle">
          {{ helperService.displayDate(item?.date_previsionnelle) }}
        </template>
        <template v-else-if="item?.stade">
          {{ item?.stade?.code_bbch }} - {{
            item.stade.designation_court ?
            item.stade.designation_court :
            item.stade.designation
          }}
        </template>
        <template v-else>
          —
        </template>
      </template>

      <template v-slot:date_realisation="{ item }">

        <template v-if="item?.type?.uid === 'NOTATION' && states[item.id] !== 'REALISE'">
          -
        </template>
        <template v-else>
          {{ helperService.displayDate(item?.date_realisation) }}
        </template>

      </template>

      <template v-slot:etat="{ item }">
        <template v-if="item?.type?.uid === 'NOTATION' && statesFullText[item.id]">
          {{ statesFullText[item.id] }}
        </template>
        <template v-else>
          —
        </template>
      </template>

      <template v-slot:actions="{ item }">
        <Btn v-if="item.observations" icon="info-circle" round grow @click="openModal('viewComment', item)" />
        <ToolDropdown>
          <MenuItem v-if="helperService.userHasPermission('essaievenement_oshow')" :item-data="{
            label: 'Consulter',
            iconName: 'visibility',
            route: {
              name: 'essaiExperimentalTask',
              params: {
                id: essai.id,
                tid: item.id
              },
            }
          }" />
          <MenuItem v-if="['SEMIS', 'TRAITEMENTFERTI', 'TRAITEMENTPHYTO'].includes(item.type.uid)" :item-data="{
            label: 'Documents',
            iconName: 'document',
          }" @click="openModal('downloadFiles', item)" />
          <MenuItem v-if="(item.type.uid !== 'NOTATION' ||
            item.type.uid === 'NOTATION' && states[item.id] === 'ENPREPARATION') &&
            helperService.userHasPermission(
              'essaievenement_oedit,essaievenement_oremove', 'OR'
            )
            " :item-data="{
    label: 'Éditer/Supprimer',
    iconName: 'create',
    route: {
      name: 'essaiExperimentalTaskGeneralInformationEdit',
      params: {
        id: essai.id,
        tid: item.id
      },
    }
  }" />
          <MenuItem v-if="item.type.uid === 'NOTATION' && checkNotation(item) && notations[item.id]" :item-data="{
            label: 'Export des donnees',
            iconName: 'share-alt',
          }" @click="downloadFile('xls', 'notation', notations[item.id], 'donnees-consolidees')" />
          <MenuItem v-if="item.type.uid === 'NOTATION' && findNotationWithProtocolParent(item.id)" :item-data="{
            label: 'Fusionner',
            iconName: 'merge',
          }" @click="openModal('mergeNotation', item)" />
          <MenuItem v-if="item.date_realisation
            && helperService.userHasPermission('essaievenement_oedit')
            " :item-data="{
    label: 'Modifier la date de réalisation',
    iconName: 'check',
  }" @click="openModal('realizeEvent', item)" />

          <MenuItem v-if="!item.date_realisation
            && item.type.uid !== 'NOTATION'
            && helperService.userHasPermission('essaievenement_oedit')
            " :item-data="{
    label: 'Réaliser',
    iconName: 'check',
  }" @click="openModal('realizeEvent', item)" />

          <MenuItem v-if="helperService.userHasPermission('log_list')" :item-data="{
            label: 'Voir les logs',
            route: {
              name: 'ressourceLogs',
              params: {
                ressource: 'essai',
                rid: 'essai',
                ressourceid: item.id,
                from: 'essaiCalendar',
                title: `Voir les logs`,
                fromRessourceId: $route.params.id
              },
            },
            iconName: 'clipboard-list',
          }" />

        </ToolDropdown>

      </template>
    </List>
  </div>

  <Modal :key="componentKey" title="Ajouter une tâche expérimentale" :active="modal.addExperimentalTask" :data="modalData"
    :containForm="true" :modalValidationSchema="validationSchema" @modal-close="modal.addExperimentalTask = false"
    @modal-form-submitted="handleValues($event)" noScroll>
    <template v-slot:modal-body>
      <SelectExtended v-if="event_types.length" v-model="form.experimentalTask" label="Tâche expérimentale"
        optionKey="uid" optionValue="designation" :items="event_types" required />

      <Input id="designation" label="Désignation" />

      <Input id="date_previsionnelle" label="Date prévisionnelle" type="date" />
      <p class="h6">Ou</p>

      <SelectExtended id="stade_id" label="Stade prévisionnel" optionKey="id" optionValue="designation_calculee"
        apiEndpoint="dictionnaire/stade" :apiParams="stade.apiParams" />

      <Input id="date_realisation" label="Date de réalisation" type="date" />
      <Input id="observations" label="Observations" type="textarea" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.addExperimentalTask = false" />
      <Btn btnType="submit" text="Ajouter" color="primary" />
    </template>
  </Modal>

  <Modal title="Commentaire de la tâche expérimentale" :active="modal.viewComment" :data="modalData"
    @modal-close="modal.viewComment = false">
    <template v-slot:modal-body="{ data }">
      {{ data?.observations }}
    </template>
    <template v-slot:modal-footer>
      <Btn color="primary" text="Fermer" @click="modal.viewComment = false" />
    </template>
  </Modal>

  <Modal title="Télécharger un document" :active="modal.downloadFiles" :data="modalData"
    @modal-close="modal.downloadFiles = false">
    <template v-slot:modal-body="{ data }">
      <div class="grid">
        <Btn text="Etiquettes produits" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="closeOpenModal('downloadFiles', 'downloadLabels', data)" />
      </div>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.downloadFiles = false" />
    </template>
  </Modal>

  <Modal title="Télécharger une planche d'étiquettes PRODUIT" :active="modal.downloadLabels" :data="modalData"
    @modal-close="modal.downloadLabels = false" no-scroll>
    <template v-slot:modal-body>
      <div class="grid">
        <SelectExtended class="margin-bottom-quad" id="modele_etiquette_id" v-model="model_etiquette_id"
          label="Modele d'étiquettes" optionKey="id" optionValue="nom" apiEndpoint="etiquette/modele"
          :apiParams="modele_etiquette.type.produit" />

      </div>

    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.downloadLabels = false" />
      <Btn text="Imprimer" color="primary" @click="downloadLabel('pdf', 'evenement',
        data.id, model_etiquette_id?.key, 'produits')" />
    </template>
  </Modal>

  <!-- Modals realizeEvent -->
  <Modal title="Déclarer la réalisation de la tâche expérimentale&nbsp;?" :active="modal.realizeEvent" :data="modalData"
    @modal-close="modal.realizeEvent = false">
    <template v-slot:modal-body>
      <Input v-model="realizationDate" required id="date_realisation" label="Date de réalisation" type="date" :options="{
        max: new Date().toISOString().split('T')[0]
      }" />
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.realizeEvent = false" />
      <Btn text="Déclarer" @click="realizeEvent(data)" icon="check" color="primary" />
    </template>
  </Modal>

  <!-- Modals mergeNotation -->
  <Modal title="Fusionner la tâche expérimentale" :active="modal.mergeNotation" :data="modalData"
    @modal-close="modal.mergeNotation = false">
    <template v-slot:modal-body="{ data }">
      <Radio v-model="selectedNotationToMerge" required id="typemodalite"
        :items="notationsToMerge.filter((notation) => notation.value !== data.id)"
        :label="`Sélectionner la notation à fusionner avec ${data.designation}`" />
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.mergeNotation = false" />
      <Btn text="Fusionner" @click="mergeNotation(data)" icon="merge" color="primary" />
    </template>
  </Modal>

</template>

<script>
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'

export default {
  name: 'CalendarSubview',

  components: {
    Radio,
    SelectExtended,
    SvgIcon,
    Input,
    Modal,
    MenuItem,
    ToolDropdown,
    List,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  emits: ['updateEssais'],

  data() {
    const validationSchema = this.yup.object().shape({
      designation: this.yup.string().nullable(),
      date_previsionnelle: this.yup.date().nullable().transform((c, o) => (o === '' ? null : c)),
      stade_id: this.yup.object().nullable(),
      date_realisation: this.yup.date().nullable().transform((c, o) => (o === '' ? null : c)),
      observations: this.yup.string().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      modele_etiquette: {
        type: {
          produit: {
            filters: 'type.uid:eq(PRODUIT)',
          },
        },
      },
      model_etiquette_id: {},
      states: [],
      statesFullText: [],
      notations: [],
      notationsObj: [],
      list: {
        target: {
          route: {
            name: 'essaiExperimentalTask',
            paramsFromRoute: { id: this.$route.params.id },
            itemParams: { tid: 'id' },
          },
        },
        api: {
          endpoint: `essai/${this.$route.params.id}/evenement`,
          params: {
            sort: 'id.ASC',
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Statut' },
          { label: 'Tâche expérimentale', isLink: true },
          { label: 'Prévisionnel' },
          { label: 'Réalisation' },
          { label: 'État d\'avancement' },
        ],
        col: ['statut', 'designation', 'previsionnelle', 'date_realisation', 'etat'],
        key: 'id',
      },

      modalData: {},
      modal: {
        addExperimentalTask: false,
        viewComment: false,
        downloadLabels: false,
        downloadFiles: false,
        realizeEvent: false,
        mergeNotation: false,
        deleteNotation: false,
      },
      componentKey: 0,
      form: {
        experimentalTask: {},
      },
      realizationDate: new Date().toISOString().split('T')[0],
      event_types: [],
      notationsToMerge: [],
      selectedNotationToMerge: null,
      notationsWithProtocolParent: [],
      onSubmit,
      errors,
      isSubmitting,
      validationSchema,
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },
    }
  },

  watch: {
  },

  mounted() {
    this.getEventTypes()
    this.getProtocol()
  },

  methods: {
    /** ***********************************************
     * Modal
     ********************************************** */
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    closeOpenModal(toClose, toOpen, data) {
      this.modal[toClose] = false
      this.modalData = data
      this.modal[toOpen] = true
    },

    /** ***********************************************
     * Getters
     ********************************************** */

    getProtocol() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocoleId = response.data.protocole.id
        this.fetchService.get(`protocole/${this.protocoleId}`).then((protocole) => {
          const cultures = protocole.data.cultures.map((item) => item.uid)
          if (cultures.length > 0) {
            this.stade.apiParams = {
              sort: 'ordre.ASC',
              filters: `culture.uid:in(${cultures})`,
            }
          }
        })
      })
    },
    /**
     * Type d'événement
     */
    getEventTypes() {
      this.fetchService.get('dictionnaire/type/evenement?sort=designation.ASC').then(
        (response) => {
          const datas = response.data
          this.event_types = datas.filter((et) => et.uid !== 'TRAITEMENTFERTI' && et.uid !== 'TRAITEMENTPHYTO')
        },
      )
    },

    async checkNotation(event) {
      const { params } = this.$route
      const not = await this.fetchService.get(
        `essai/${params.id}/evenement/${event.id}/fiche/notation`,
      )

      let notation = await not.data.pop()

      const res = await this.fetchService.get(
        `essai/${params.id}/evenement/${event.id}/fiche/notation/${notation.id}/reservation?sort=id.DESC`,
      )

      let reservation = await res.data.shift()

      this.states[event.id] = reservation?.etat?.uid || 'ENPREPARATION'
      this.statesFullText[event.id] = reservation?.etat?.designation || 'En préparation'
      this.notationsObj[event.id] = notation

      if (reservation && reservation?.etat?.uid === 'REALISE') {
        this.notations[event.id] = notation.id
        this.statesFullText[event.id] = 'Réalisé'
        return true
      }

      if (reservation?.etat?.uid === 'PARTIELLEMENTREALISE' || reservation?.etat?.uid === 'ENCOURS') {
        if (this.notationService.getItem('reservations', reservation?.id)) {
          reservation = this.notationService.getItem('reservations', reservation?.id)
          notation = reservation?.notation
        }

        this.statesFullText[event.id] = `${reservation?.etat?.designation} (${notation.progression}%)`
      }

      return false
    },
    /**
     * Rempli le tableau de notations qui peuvent être fusionnées
     * @param notation
     */
    setNotationsToMerge(notation) {
      if (!this.notationsToMerge.find((n) => n.value === notation.id)) {
        this.notationsToMerge.push({
          label: notation.designation ?? '',
          value: notation.id,
        })
      }
    },
    /**
     * Permet de fusionner deux notations ensemble
     * @param item Événement
     */
    mergeNotation(item) {
      /**
       * TODO: JL
       * @see http://gitlab.diatem.net/outils/api-oss/-/issues/294
       */
      this.emitter.emit('open-loader')
      const ficheId = this.findNotationWithProtocolParent(item.id).fiche.id
      this.fetchService.patch(`essai/${item.essai.id}/evenement/${item.id}/fiche/notation/${ficheId}/fusion/${this.selectedNotationToMerge}`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Les notations ont bien été fusionnées.',
          })
          this.emitter.emit('close-loader')
        })
      this.modal.mergeNotation = false
    },

    deleteNotation(item) {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`essai/${item.essai.id}/evenement/${item.id}`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Les notations ont bien été supprimé.',
          })
          this.emitter.emit('close-loader')
        })
      this.modal.deleteNotation = false
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },

    /** ***********************************************
     * Download
     ********************************************** */
    /**
     * Fichier
     * @param type
     * @param ressource
     * @param rid
     * @param childRessource
     */
    downloadFile(type, ressource, rid, childRessource = null) {
      let endpoint = `taches/type/${type}/ressource/${ressource}/${rid}`

      if (childRessource) {
        endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/${childRessource}`
      }

      const filename = childRessource ? `${ressource}_${rid}_${childRessource}` : `${ressource}_${rid}`

      this.fileService.downloadFile(endpoint, filename, type)
    },
    /**
     * Étiquettes
     * @param type
     * @param ressource
     * @param rid
     * @param mid
     * @param labelType
     */
    downloadLabel(type, ressource, rid, mid, labelType) {
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/etiquettes/${mid}/${labelType}`
      const filename = `essai_${rid}_etiquettes_${labelType}`
      this.fileService.downloadFile(endpoint, filename, type)

      this.modal.downloadLabels = false
    },

    /** ***********************************************
     * Submit
     ********************************************** */
    /**
     * Permet de noter la tâche expérimentation comme réalisé
     * @param item
     */
    realizeEvent(item) {
      const dateRealization = Date.parse(this.realizationDate)

      if (this.realizationDate === '') {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'a pas été renseignée.',
        })
      } else if (this.realizationDate !== '' && dateRealization > Date.now()) {
        this.emitter.emit('alert', {
          type: 'error',
          content: 'La date de réalisation de la tâche expérimentale n\'est pas valide.',
        })
      } else {
        this.emitter.emit('open-loader')
        this.fetchService.put(`essai/${item.essai.id}/evenement/${item.id}`, {
          date_realisation: this.helperService.formatDateForApi(this.realizationDate),
        })
          .then(() => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La date de réalisation de la tâche expérimentale a bien été modifiée.',
            })

            this.$nextTick(() => {
              this.componentKey += 1
            })
          })
        this.modal.realizeEvent = false
        this.emitter.emit('close-loader')
      }
    },
    handleValues(values) {
      this.emitter.emit('open-loader')

      // eslint-disable-next-line no-param-reassign
      values.type_uid = this.form.experimentalTask.key
      // eslint-disable-next-line no-param-reassign
      values.stade_id = values?.stade_id?.key
      // eslint-disable-next-line no-param-reassign
      values.date_realisation = this.helperService.formatDateForApi(
        values?.date_realisation,
      )
      // eslint-disable-next-line no-param-reassign
      values.date_previsionnelle = this.helperService.formatDateForApi(
        values?.date_previsionnelle,
      )

      this.fetchService.post(`essai/${this.essai.id}/evenement`, values)
        .then((response) => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La tâche expérimentale a bien été créée.',
          })

          this.$router.push({
            name: 'essaiExperimentalTaskGeneralInformationEdit',
            params: {
              id: this.essai.id,
              tid: response.data.id,
            },
          })
        })
        .catch((responseError) => {
          this.formService.handleApiError(responseError)
        })

      this.modal.addExperimentalTask = false
      this.emitter.emit('close-loader')
    },
    /** ***********************************************
     * Helpers
     ********************************************** */
    findNotationWithProtocolParent(eventId) {
      return this.notationsWithProtocolParent.find(((n) => n.task && n.task.id === eventId))
    },
  },
}
</script>

<style lang="scss" scoped>
.essai-statut {
  height: 15px;
  width: 15px;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  transform: rotate(45deg);

  &--filled {
    border: 1px solid $color-primary;
    background: $color-primary;
  }
}

.essai-bpe {
  height: 25px;
  width: 25px;
  border: 10px solid $color-primary;
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: $font-size-smaller;
  font-weight: $font-weight-lighter;
}

.essai-check {
  height: 40px;
  width: 40px;
  border: 2px solid $color-primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary;
}
</style>
